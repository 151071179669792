<template>
  <div>
    <div class="mb-5">
      <div class="grid grid-cols-1 border border-blue-200 xl:grid-cols-2">
        <div class="col-span-1 p-4">
          <div class="mb-1 text-sm font-medium opacity-75">
            Total Income
          </div>
          <div
            class="flex flex-row items-center text-sm font-light text-gray-500"
          >
            {{ totalAmount | formatAmount }}
          </div>
        </div>
        <div
          class="col-span-1 p-4 border-t border-blue-200 xl:border-t-0 xl:border-l"
        >
          <div class="mb-1 text-sm font-medium opacity-75">
            Total Transaction
          </div>
          <div
            class="flex flex-row items-center text-sm font-light text-gray-500"
          >
            {{ totalOrders }}
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 border border-blue-200 xl:grid-cols-2">
        <div class="col-span-1 p-4">
          <div class="mb-1 text-sm font-medium opacity-75">
            Pending Transaction
          </div>
          <div
            class="flex flex-row items-center text-sm font-light text-gray-500"
          >
            {{ pendingOrders }}
          </div>
        </div>
        <div
          class="col-span-1 p-4 border-t border-blue-200 xl:border-t-0 xl:border-l"
        >
          <div class="mb-1 text-sm font-medium opacity-75">
            State
          </div>
          <div
            class="flex flex-row items-center text-sm font-light text-gray-500"
          >
            <ion-icon name="map-outline" class="mr-2 text-lg"></ion-icon>
            {{ mapProfileData(description, 'state') }}
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 border border-blue-200 xl:grid-cols-2">
        <div class="col-span-1 p-4">
          <div class="mb-1 text-sm font-medium opacity-75">
            Pending Settlements
          </div>
          <div
            class="flex flex-row items-center text-sm font-light text-gray-500"
          >
            <ion-icon name="pricetag-outline" class="mr-2 text-lg"></ion-icon>
            {{ pendingSettlements | formatAmount }}
          </div>
        </div>
        <div
          class="col-span-1 p-4 border-t border-blue-200 xl:border-t-0 xl:border-l"
        >
          <div class="mb-1 text-sm font-medium opacity-75">
            Paid Settlements
          </div>
          <div
            class="flex flex-row items-center text-sm font-light text-gray-500"
          >
            <ion-icon name="pricetag-outline" class="mr-2 text-lg"></ion-icon>
            {{ paidSettlements | formatAmount }}
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 border border-blue-200 xl:grid-cols-2">
        <div class="col-span-1 p-4">
          <div class="mb-1 text-sm font-medium opacity-75">
            Regions
          </div>
          <div
            class="flex flex-row items-center text-sm font-light text-gray-500"
          >
            <ion-icon name="location-outline" class="mr-2 text-lg"></ion-icon>
            {{ regions }}
          </div>
        </div>
        <div
          class="col-span-1 p-4 border-t border-blue-200 xl:border-t-0 xl:border-l"
        >
          <div class="mb-1 text-sm font-medium opacity-75">
            Branches
          </div>
          <div
            class="flex flex-row items-center text-sm font-light text-gray-500"
          >
            <ion-icon name="location-outline" class="mr-2 text-lg"></ion-icon>
            {{ branches }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  props: {
    profile: {
      required: true,
      type: [Array, Object],
      default: null
    }
  },
  computed: {
    ...mapState(['banks']),
    description() {
      return this.profile?.descriptions;
    },
    totalAmount() {
      return this.profile?.orders
        ?.filter(
          (order) => order?.status === 'success' || order?.status === 'disbursed'
        )
        ?.reduce((total, order) => {
          return total + Number(order?.price);
        }, 0);
    },
    totalOrders() {
      return this.profile?.orders?.length;
    },
    pendingOrders() {
      return this.profile?.orders?.filter((order) => order.status === 'pending')
        ?.length;
    },
    pendingSettlements() {
      return this.profile?.cashbacks
        ?.filter((cashback) => cashback?.status === 'pending')
        ?.reduce((total, settlement) => {
          return total + Number(settlement?.cashback);
        }, 0);
    },
    paidSettlements() {
      return this.profile?.cashbacks
        ?.filter((cashback) => cashback?.status === 'paid')
        ?.reduce((total, settlement) => {
          return total + Number(settlement?.cashback);
        }, 0);
    },
    selectedBank() {
      const bank = this.banks.length
        ? this.banks?.find((bank) => bank.code === this.description?.bank_name)
        : [];

      return bank?.name || 'N/A';
    },
    branches() {
      return this.profile?.branches?.length || 0;
    },
    regions() {
      return this.profile?.regions?.length || 0;
    }
  },
  methods: {
    mapProfileData(data, key) {
      return (data && data?.[key]) || 'N/A';
    }
  }
};
</script>
